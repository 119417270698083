import dayjs, { Dayjs } from "dayjs";
import router from "next/router";
import { atom, selector } from "recoil";
import { makeUrl } from "../fe-ui/utils/makeUrl";
import { adultsCounterAtom, childrenCounterAtom } from "./booking-state";

export type IDateRangeSerialized = [string | null, string | null];

export const dateRangeAtom = atom<IDateRangeSerialized>({
  key: "search/date-range",
  default: (() => {
    return [null, null];
  })(),
  effects: [
    ({ onSet }) => {
      onSet(([arrivalDate, departureDate]) => {
        if (arrivalDate && departureDate && router.query && router.query.departureDate && router.query.arrivalDate) {
          router.replace(
            {
              query: { ...router.query, arrivalDate, departureDate },
            },
            undefined,
            {
              shallow: true,
            }
          );
        }
      });
    },
  ],
});

export const dateRangeAvailableSelector = selector({
  key: "search/date-range-is-available",
  get({ get }) {
    const [a, b] = get(dateRangeAtom);
    return Boolean(a && b);
  },
});

export const dateRangeNightsCounterSelector = selector({
  key: "search/date-range-nights-count",
  get({ get }) {
    const isAvailable = get(dateRangeAvailableSelector);
    if (!isAvailable) {
      return 0;
    }
    const [a, b] = get(dateRangeAtom);
    return dayjs(b).diff(dayjs(a), "days");
  },
});

export const singleHotelIdContextAtom = atom<string>({
  key: "search/single-hotel-id",
  default: "",
});

// TODO: Move to UI state
export const filterWidgetShownAtom = atom<boolean>({
  key: "search/widget-shown",
  default: false,
});

export const filterSelectedAttributeIdsAtom = atom<number[]>({
  key: "search/selected-attrs",
  default: [],
  effects: [
    // urlSyncEffect({
    //   refine: array(number()),
    //   history: "push"
    // }),
  ],
});

export const filterSelectedAttributeIdsSelector = selector({
  key: "search/selected-attrs-ids",
  get({ get }) {
    return get(filterSelectedAttributeIdsAtom);
  },
});

export const searchFormSelector = selector({
  key: "search/search-form-selectors",
  get({ get }) {
    const dateRangeSource = get(dateRangeAtom);
    const dateRange = dateRangeSource.map((d) => (d ? dayjs(d) : null)) as [Dayjs | null, Dayjs | null];
    return {
      dateRange,
    };
  },
});

export const selectedCityIdAtom = atom<number | undefined>({
  key: "search/city-id",
  default: undefined,
});

export const selectedCityNameAtom = atom<string | undefined>({
  key: "search/city-name",
  default: undefined,
});

export const selectedHotelAtom = atom<string>({
  key: "search/hotel",
  default: "",
});

export const sortOrderAtom = atom<string>({
  key: "search/sortOrder",
  default: "asc",
});

export const minPriceAtom = atom<number>({
  key: "search/minPrice",
  default: 0,
});

export const maxPriceAtom = atom<number>({
  key: "search/maxPrice",
  default: 5000,
});

export const searchFullUrlSelector = selector({
  key: "search/search-full-url-string",
  get({ get }) {
    const cityId = get(selectedCityIdAtom) ?? "";
    const hotelId = get(selectedHotelAtom);
    const dateRangeSource = get(dateRangeAtom);
    const [arrivalDate, departureDate] = dateRangeSource;
    const filters = get(filterSelectedAttributeIdsSelector).toString();
    const adults = get(adultsCounterAtom);
    const children = get(childrenCounterAtom);
    return makeUrl("/search", {
      cityId,
      hotelId,
      arrivalDate,
      departureDate,
      filters,
      children,
      adults,
    }) as "/search";
  },
});
